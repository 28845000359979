








import { defineComponent } from "@vue/composition-api";
import DefaultLayout from "@/layouts/default.vue";
import ContainerCreateCategoryPage from "@/components/containers/ContainerCreateCategoryPage.vue";
export default defineComponent({
 components: {
  DefaultLayout,
  ContainerCreateCategoryPage,
 },
 created() {
  this.$store.commit("SET_BREADCRUMB", {
   viewTxt: "Danh mục",
   ableToBack: false,
   rootRouter: "/",
   hasStatus: false,
   statusTxt: "",
  });
 },

 mounted() {
  this.$store.commit("SET_ADDLIB", false);
 },
});
