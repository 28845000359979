












































import {defineComponent,ref,watch} from "@vue/composition-api"
export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    handleDialogCancel:{
        type:Function
    }
    
  },
  setup: (props, ctx) => {
    const btnCancelClick= ()=>{
        ctx.emit("handleDialogCancel")
    }
    return {
        btnCancelClick
    };
  },


})
