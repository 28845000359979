












































































































































































































































































































































































































































































































































































































	import UploadFile from '@/components/Layout/page-view/propose/UploadFile.vue';
	import api from '@/services';
	import { defineComponent, ref, reactive, toRef, onMounted } from '@vue/composition-api';

	import DialogCategoryEditorCK4 from '@/components/Form/DialogCategoryEditorCK4.vue';
	import DialogAddApprovalPeople from '@/components/Form/DialogAddApprovalPeople.vue';
	import DialogChooseSignature from '@/components/Form/DialogChooseSignature.vue';
	import PreviewTemplate from '@/components/popup/PreviewTemplate.vue';
	import ConfirmRemoveTemplate from '@/components/popup/ConfirmRemoveTemplate.vue';
	import DialogAddMemberToCate from '@/components/Form/DialogAddMemberToCate.vue';
	import { fileClipboardObj } from '@/InterfaceModel/Files';
	import FieldImformation from '@/components/Shared/FieldImformation.vue';
	import useRouteQuery from '@/utils/uses/routerQuery/useRouteQuery';
	import { useState } from 'vuex-composition-helpers/dist';
	import { tStr } from 'validation_t/src';
	import { mapState } from 'vuex';

	export default defineComponent({
		components: {
			DialogAddApprovalPeople,
			DialogChooseSignature,
			UploadFile,
			DialogAddMemberToCate,
			FieldImformation,
			DialogCategoryEditorCK4,
			ConfirmRemoveTemplate,
			PreviewTemplate,
		},
		setup: (props, ctx) => {
			const { queryRoute, currentParram } = useRouteQuery();
			let loadingBtnRemoveTemp = false;
			const currentID: number = currentParram;
			let setKeyRFM = ref<number>(1);
			let keyFI = ref<number>(1);
			let fileClipboard = ref<fileClipboardObj[]>([]);
			let isEdit = ref<boolean>(false);
			let visibleConfirmRemoveTemplate = ref<boolean>(false);
			let visibleEditor = ref<boolean>(false);
			let listApprovalMember = ref<any[]>([]);
			let listFollowersMember = ref<any[]>([]);
			let cateName = ref<string>('');
			let cateAvatar = ref<string>('');
			let cateDescription = ref<string>('');
			let cateCode = ref<string>('');
			let cateVersion = ref<string>('');
			let cateNote = ref<string>('');
			let errMsg = ref<string>('');
			let loadingSubmit = ref<boolean>(false);
			let fileClipboardS = ref<fileClipboardObj>();
			let visibleAddMember = ref<boolean>(false);
			let visibleAddMemberF = ref<boolean>(false);
			let visiblePreviewTemplate = ref<boolean>(false);
			let listTemplate = ref<any[]>([]);
			let listParam = ref<Record<string, any>>({});
			let defaultTitles = ref<Record<string, any>[]>([]);
			let defaultFollowers = ref<Record<string, any>[]>([]);
			let removeTemplate = ref<Record<string, any>>({});
			let previewTemplate = ref<Record<string, any>>({});
			let selectedTemplate = ref<Record<string, unknown>>({});
			const company = ref<Record<string, unknown>>();
			const department = ref<number>();
			const setFileClipboard = (payload: fileClipboardObj[]) => {
				fileClipboard.value = payload;
			};

			const setIsEdit = (payload: boolean) => {
				isEdit.value = payload;
			};
			const setSetKeyRFM = (payload: number) => {
				setKeyRFM.value = payload;
			};
			const setVisibleEditor = (payload: boolean) => {
				visibleEditor.value = payload;
			};
			const setVisibleAddMemberF = (payload: boolean) => {
				visibleAddMemberF.value = payload;
			};
			const setVisibleConfirmRemoveTemplate = (payload: boolean) => {
				visibleConfirmRemoveTemplate.value = payload;
			};
			const setVisiblePreviewTemplate = (payload: boolean) => {
				visiblePreviewTemplate.value = payload;
			};
			const setLoadingSubmit = (payload: boolean) => {
				loadingSubmit.value = payload;
			};
			const setErrMsg = (payload: string) => {
				errMsg.value = payload;
			};
			const setCateAvatar = (payload: string) => {
				cateAvatar.value = payload;
			};
			const setCateName = (payload: string) => {
				cateName.value = payload;
			};
			const setCateNote = (payload: string) => {
				cateNote.value = payload;
			};
			const setDepartment = (payload: number) => {
				department.value = payload;
			};
			const setCateDescription = (payload: string) => {
				cateDescription.value = payload;
			};

      const setCateCode = (payload: string) => {
        cateCode.value = payload;
      };
      const setCateVersion = (payload: string) => {
        cateVersion.value = payload;
      };


      const clearFileClipboard = () => {
				fileClipboard.value = [{ _id: 0, type: 'none', blob: 'none' }];
			};

			const setFileClipboardS = (payload: fileClipboardObj) => {
				fileClipboardS.value = payload;
			};
			const setVisibleAddMember = (payload: boolean) => {
				visibleAddMember.value = payload;
			};
			const setListApprovalMember = (payload: any[]) => {
				listApprovalMember.value = payload;
			};
			const setListFollowersMember = (payload: any[]) => {
				listFollowersMember.value = payload;
			};
			const setDefaultTitles = (payload: Record<string, any>[]) => {
				defaultTitles.value = payload;
			};
			const setDefaultFollowers = (payload: Record<string, any>[]) => {
				defaultFollowers.value = payload;
			};
			const setRemoveTemplate = (payload: Record<string, any>) => {
				if (payload.id) {
					setVisibleConfirmRemoveTemplate(true);
				}
				removeTemplate.value = payload;
			};
			const setPreviewTemplate = (payload: Record<string, any>) => {
				if (payload.html) {
					setVisiblePreviewTemplate(true);
				}
				previewTemplate.value = payload;
			};
			const setListTemplate = (payload: any[]) => {
				listTemplate.value = payload;
			};
			const { others } = useState(['others']);

			const handlerRemoveFeild = (idx: number) => {
				if (idx >= 0) {
					listFeildSelected.value.splice(idx, 1);
				}
			};
			const handlerChangeSelect = (e: any) => {
				if (!e) {
					return;
				}
				const paydload = { ...e };
				if (e.id === 10) {
					paydload.tableStatistic = [{ name: 'Cột 1' }, { name: 'Cột 2' }];
				}
				if (e.id === 12) {
					paydload.tableCurrency = [
						{ name: 'Cột 1', type: 'text' },
						{ name: 'Cột 2', type: 'text' },
					];
				}
				listFeildSelected.value.push(paydload);
				setSetKeyRFM(Math.round(Math.random() * 1000));
			};
			const handleAddCol = (value: any, idx: number) => {
				const payload = [...listFeildSelected.value];
				payload[idx].tableStatistic.push({
					name: `Cột ${payload[idx].tableStatistic.length + 1}`,
				});
				// if (payload && payload.tableStatistic) {
				//   payload.tableStatistic
				// }
				// listFeildSelected.value.push(paydload);
				// setSetKeyRFM(Math.round(Math.random() * 1000));
			};
			const handleAddColCurrency = (value: any, idx: any) => {
				const payload = [...listFeildSelected.value];
				payload[idx].tableCurrency.push({
					name: `Cột ${payload[idx].tableCurrency.length + 1}`,
					type: 'text',
				});
				listFeildSelected.value = payload;
				keyFI.value = Math.round(Math.random() * 1000);
			};
			const updateField = (_val: any, _idx: number) => {
				listFeildSelected.value[_idx] = _val;
			};
			const setListFeildSelected = (_val: Array<any>) => {
				listFeildSelected.value = _val;
			};
			const setListParam = (_val: Record<string, any>) => {
				listParam.value = _val;
			};
			const setSelectedTemplate = (_val: Record<string, any>) => {
				selectedTemplate.value = _val;
			};
			// get current id detail
			const getDetail = async () => {
				if (!currentID) {
					return;
				}
				setIsEdit(true);
				const res = await api.categories.getDetail(currentID);
				if (!res) {
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						const dataObj = res.data.data.category;
						setCateName(dataObj.name);
						setCateNote(dataObj.note);
						setCateAvatar(dataObj.avatar);
						setCateDescription(dataObj.description);
						setCateCode(dataObj.code);
						setCateVersion(dataObj.version);
						setCateDescription(dataObj.description);
						setDepartment(dataObj.department.id);
						// setListApprovalMember(dataObj.default_approvers);
						setListTemplate(dataObj.templates || []);
						setListParam(dataObj.parameters || []);
						setDefaultTitles(dataObj.default_titles || []);
						setDefaultFollowers(dataObj.default_followers || []);
						const arr = dataObj.fields.map((o: any) => {
							return {
								id: o.type.id,
								alias: o.type.alias,
								name: o.type.name,
								required: o.required,
								caption: o.caption,
								dynamic_attributes: o.dynamic_attributes,
								tableStatistic:
									o.type.id === 10 && o.dynamic_attributes && o.dynamic_attributes.columns
										? o.dynamic_attributes.columns.map((o: any) => {
												return { name: o };
										  })
										: [],
								tableCurrency:
									o.type.id === 12 && o.dynamic_attributes && o.dynamic_attributes.columns
										? o.dynamic_attributes.columns.map((o: any) => {
												const obj = { ...o };
												return { name: obj.name, type: obj.type };
										  })
										: [],
							};
						});
						setListFeildSelected(arr);
						// console.log(arr)
					}
				} catch (error) {
					console.log(error);
				}
			};
			const listIFM = others.value.listFieldAccepted;
			const listFeildSelected = ref<Array<any>>([]);

			onMounted(getDetail);
			return {
				isEdit,
				cateAvatar,
				cateName,
				cateNote,
				cateDescription,
				cateCode,
				cateVersion,
				others,
				listIFM,
				company,
				department,
				visibleEditor,
				visibleConfirmRemoveTemplate,
				loadingBtnRemoveTemp,
				errMsg,
				currentID,
				setKeyRFM,
				listTemplate,
				removeTemplate,
				loadingSubmit,
				fileClipboard,
				fileClipboardS,
				visibleAddMember,
				visibleAddMemberF,
				listApprovalMember,
				listFollowersMember,
				listFeildSelected,
				listParam,
				previewTemplate,
				selectedTemplate,
				defaultTitles,
				defaultFollowers,
				visiblePreviewTemplate,
				setVisiblePreviewTemplate,
				setPreviewTemplate,
				setFileClipboard,
				clearFileClipboard,
				setFileClipboardS,
				setVisibleAddMember,
				setVisibleAddMemberF,
				setListApprovalMember,
				setListFollowersMember,
				handlerRemoveFeild,
				handlerChangeSelect,
				updateField,
				setLoadingSubmit,
				setErrMsg,
				setVisibleEditor,
				setListTemplate,
				setSelectedTemplate,
				getDetail,
				setListParam,
				setCateNote,
				setDefaultTitles,
				setDefaultFollowers,
				setCateAvatar,
				handleAddCol,
				setVisibleConfirmRemoveTemplate,
				setRemoveTemplate,
				handleAddColCurrency,
				keyFI,
			};
		},
		data() {
			return {
				ckKeyRef: false,
				listAddedSigner: [
					{
						title: '',
					},
				],
				listAddedFollower: [{}],
				selectedDetail: {},
				approverLoading: false,
				listUnsave: [] as Record<string, any>[],
				listUnsaveF: [] as Record<string, any>[],
				selectedDefaultTitles: {} as Record<string, any>,
				selectedDefaultTitlesF: {} as Record<string, any>,
				selectedNewTitles: {} as Record<string, any>,
				selectedNewTitlesF: {} as Record<string, any>,
				dialogInputTitle: false as boolean,
				dialogInputTitleF: false as boolean,
			};
		},
		watch: {
			defaultTitles: {
				deep: true,
				handler() {
					if (this.defaultTitles && this.defaultTitles.length) {
						this.listAddedSigner = [];
					}
					if (this.defaultTitles && !this.defaultTitles.length) {
						this.listAddedSigner = [
							{
								title: '',
							},
						];
					}
				},
			},
			defaultFollowers: {
				deep: true,
				handler() {
					if (this.defaultFollowers && this.defaultFollowers.length) {
						this.listAddedFollower = [];
					}
					if (this.defaultFollowers && !this.defaultFollowers.length) {
						this.listAddedFollower = [{}];
					}
				},
			},
		},
		computed: {
			...mapState({
				listDepartments: (state: any) => state.users.listDepartments,
				listCompanies: (state: any) => state.users.listCompanies,
				listUsers: (state: any) => state.users.listUsers,
				listIFMState: (state: any) => state.others.listFieldAccepted,
			}),
		},
		methods: {
			forceUpdate() {
				this.$forceUpdate();
			},
			pushChange(_data: Record<string, any>) {
				const idx = this.listUnsave.findIndex((o: Record<string, any>) => o.id === _data.id);
				if (idx === -1) {
					this.listUnsave.push(_data);
				}
			},
			pushChangeF(_data: Record<string, any>) {
				const idx = this.listUnsaveF.findIndex((o: Record<string, any>) => o.id === _data.id);
				if (idx === -1) {
					this.listUnsaveF.push(_data);
				}
			},
			removeChange(_data: Record<string, any>) {
				const idx = this.listUnsave.findIndex((o: Record<string, any>) => o.id === _data.id);
				if (idx !== -1) {
					this.listUnsave.splice(idx, 1);
				}
			},
			removeChangeF(_data: Record<string, any>) {
				const idx = this.listUnsaveF.findIndex((o: Record<string, any>) => o.id === _data.id);
				if (idx !== -1) {
					this.listUnsaveF.splice(idx, 1);
				}
			},
			handlerCopyFeild(idx: number) {
				const arr = this.listFeildSelected.slice();
				const obj = { ...arr[idx] };
				obj.caption += ' - Copy';
				this.listFeildSelected.push(obj);
			},
			onSortDown(idx: number) {
				const arr = this.listFeildSelected.slice();
				const temp = arr[idx];
				arr[idx] = arr[idx + 1];
				arr[idx + 1] = temp;
				this.listFeildSelected = arr.slice();
			},
			onSortUp(idx: number) {
				const arr = this.listFeildSelected.slice();
				const temp = arr[idx];
				arr[idx] = arr[idx - 1];
				arr[idx - 1] = temp;
				this.listFeildSelected = arr.slice();
			},
			handleCancel() {
				this.$router.push('/danh-muc');
			},
			getFileName(_name: string) {
				const lastDotIdx = _name.lastIndexOf('.');
				const name = _name.slice(0, lastDotIdx);
				const type = _name.slice(lastDotIdx + 1, _name.length);
				if (name.length < 20) {
					return _name;
				}
				return name.slice(0, 20) + '...' + type;
			},
			onFileChange(e: any) {
				const files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;
				files.forEach((file: any) => {
					const reader = new FileReader();
					reader.onload = async (event: any) => {
						const img = document.createElement('img');
						img.src = event.target.result;
						const currentF = this.fileClipboard.slice();
						currentF.push({
							_id: `${new Date().getTime()}_${Math.random()}`,
							name: file.name,
							src: event.target.result,
							type: 'image',
							blob: file,
							sizes: file.size,
						});
						this.setFileClipboard(currentF);
					};
					reader.readAsDataURL(file);
				});
			},
			onFileChangeS(e: any) {
				const files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;
				const file = files[0];
				const reader = new FileReader();
				reader.onload = async (event: any) => {
					const img = document.createElement('img');
					img.src = event.target.result;
					this.setFileClipboardS({
						_id: `${new Date().getTime()}_${Math.random()}`,
						name: file.name,
						src: event.target.result,
						type: 'image',
						blob: file,
						sizes: file.size,
					});
					const formData = new FormData();
					formData.append('avatar', file);
					//  const body = {
					//   _method: "POST",
					//   avatar: event.target.result,
					//  };
					const res = await api.categories.updateAvatar(this.currentID, formData);
					if (!res) {
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
						});
					}
					try {
						if (res.status > 199 && res.status < 399) {
							this.$store.commit('SET_SNACKBAR', {
								type: 'success',
								title: '',
								content: 'Cập nhật ảnh đại diện thành công',
							});
							this.setCateAvatar(event.target.result);
							this.$store.dispatch('categories/getListCategories');
						} else {
							this.setErrMsg(res.data.message || res.data.data.error);
							this.$store.commit('SET_SNACKBAR', {
								type: 'error',
								title: '',
								content: res.data.message || res.data.data.error,
							});
						}
					} catch (error) {
						console.log(error);
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
						});
					}
				};
				reader.readAsDataURL(file);
			},
			handleCancelRemoveTemplate() {
				this.setVisibleConfirmRemoveTemplate(false);
				this.$nextTick(() => {
					this.setRemoveTemplate({});
				});
			},
			handleClosePreviewTemplate() {
				this.setVisiblePreviewTemplate(false);
				this.$nextTick(() => {
					this.setPreviewTemplate({});
				});
			},
			async handleRemoveTemplate() {
				const file = { ...this.removeTemplate };
				this.loadingBtnRemoveTemp = true;
				const res = await api.template.delete(this.currentID, file.id);
				this.loadingBtnRemoveTemp = false;
				if (!res) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
					});
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.$store.commit('SET_SNACKBAR', {
							type: 'success',
							title: '',
							content: 'Xóa template thành công',
						});
						const arr = this.listTemplate.filter(o => o.id !== file.id);
						this.setListTemplate(arr);
						this.$store.dispatch('categories/getListCategories');
						this.handleCancelRemoveTemplate();
					} else {
						this.setErrMsg(res.data.message || res.data.data.error);
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: res.data.message || res.data.data.error,
						});
					}
				} catch (error) {
					console.log(error);
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
					});
				}
			},
			handleOpenAddMember() {
				this.setVisibleAddMember(true);
			},
			handleCloseAddMember() {
				this.setVisibleAddMember(false);
			},
			handleCloseAddMemberF() {
				this.setVisibleAddMemberF(false);
			},
			changeApprovalMember(_val: any[]) {
				this.handleCloseAddMember();
				const obj: any = { ...this.selectedDetail };
				if (!obj.user) {
					obj.user = _val[0];
					if (obj.newIdx || obj.newIdx === 0) {
						const arr = [...this.listAddedSigner];
						arr[obj.newIdx] = obj;
						this.listAddedSigner = [...arr];
					}
					this.onUpdateDetailRow(obj);
					return;
				}
				if (_val && _val[0].id === obj.user.id) {
					return;
				}
				obj.user = _val[0];
				if (obj.newIdx || obj.newIdx === 0) {
					const arr = [...this.listAddedSigner];
					arr[obj.newIdx] = obj;
					this.listAddedSigner = [...arr];
				}
				this.onUpdateDetailRow(obj);
				// this.setListApprovalMember(_val);
			},
			changeApprovalMemberF(_val: any[]) {
				this.handleCloseAddMemberF();
				const obj: any = { ...this.selectedDetail };

				if (_val && _val[0].id === obj.id) {
					return;
				}
				if (!obj.user) {
					obj.user = _val[0];
					if (obj.newIdx || obj.newIdx === 0) {
						const arr = [...this.listAddedFollower];
						arr[obj.newIdx] = obj;
						this.listAddedFollower = [...arr];
					} else {
						// thay df cũ
						const myIdx = this.defaultFollowers.findIndex(o => o.id === obj.id);
						if (myIdx !== -1) {
							// const arr = [...this.defaultFollowers];
							// this.setDefaultFollowers()
							this.defaultFollowers.splice(myIdx, 1, _val[0]);
							this.onSubmit();
						}
					}
					// this.onUpdateDetailRowF(obj);
					return;
				}

				obj.user = _val[0];
				if (obj.newIdx || obj.newIdx === 0) {
					const arr = [...this.listAddedFollower];
					arr[obj.newIdx] = obj;
					this.listAddedFollower = [...arr];
				}
				// this.onUpdateDetailRowF(obj);
				// this.setListApprovalMember(_val);
			},
			capitalizeFirstLetter(str: string) {
				return str.charAt(0).toUpperCase() + str.slice(1);
			},
			returnName(_str: string) {
				console.log(_str);
				if (!_str) {
					return _str;
				}
				const result = tStr.removeAscentNormal(_str);
				const arrResult = result.split(' ');
				arrResult.forEach((o: string) => {
					o = this.capitalizeFirstLetter(o);
				});
				return String(arrResult.join(''))
					.replace(/[^\w\s]/gi, '')
					.replaceAll(' ', '_');
			},
			removeApprovalDefault(id: number) {
				this.setListApprovalMember(this.listApprovalMember.filter(o => o.id !== id));
			},
			validSubmit() {
				return new Promise(resolve => {
					if (!this.cateName) {
						resolve('Không được để trống tên danh mục');
						return;
					}
					if (!this.cateDescription) {
						resolve('Không được để trống mục đích');
						return;
					}
					if (!this.department) {
						resolve('Không được để trống Phòng ban');
						return;
					}
					//  if (!this.listApprovalMember || !this.listApprovalMember.length) {
					//   resolve("Không được để trống người phê duyệt");
					//   return;
					//  }

					const rsFields = this.listFeildSelected.every(o => {
						return o.id && o.caption;
					});
					if (!rsFields) {
						resolve('Bạn chưa điền đầy đủ tên hoặc thông tin các trường');
						return;
					}
					resolve('');
				});
			},
			closeEditor() {
				this.ckKeyRef = false;
				this.setSelectedTemplate({});
				this.setVisibleEditor(false);
			},
			selectTemplateToEdit(data: Record<string, any>) {
				this.ckKeyRef = true;
				this.$nextTick(() => {
					this.setSelectedTemplate(data);
					this.setVisibleEditor(true);
				});
			},
			async onSubmit() {
				const createDetail: any[] = [];
				let tCount = 0;
				this.listAddedSigner.forEach(async (o, idx) => {
					let itvn = setInterval(async () => {
						if (tCount === idx) {
							clearInterval(createDetail[idx]);
							await this.createDetailRow(o, idx, false, false);
							tCount += 1;
						}
					}, 100);
					createDetail.push(itvn);
				});

				const refIt = setInterval(async () => {
					if (this.isEdit) {
						if (tCount === this.listAddedSigner.length || (this.listAddedSigner.length >= 1 && tCount === 0)) {
							clearInterval(refIt);
							this.listAddedSigner = [];
							this.listUnsave.forEach(async o => {
								this.onUpdateDetailRow(o, false, false, false);
							});
							this.listUnsaveF.forEach(async o => {
								this.onUpdateDetailRow(o, false, false, false);
							});

							this.setErrMsg('');
							const isValid = await this.validSubmit();

							if (!isValid) {
								const fields = this.listFeildSelected.map(o => {
									let obj = {
										type_id: o.id,
										caption: o.caption,
										required: !!o.required,
										dynamic_attributes: {},
									};
									if (o.id === 3) {
										if (o.sendValue) {
											obj.dynamic_attributes = {
												choices: o.sendValue ? o.sendValue.map((k: any) => k.txt) : [],
											};
										} else {
											if (o.dynamic_attributes && o.dynamic_attributes.choices) {
												const sendValue = o.dynamic_attributes.choices.map((k: any) => {
													return {
														txt: k,
													};
												});
												o.sendValue = sendValue;
												obj.dynamic_attributes = {
													choices: sendValue ? sendValue.map((k: any) => k.txt) : [],
												};
											}
										}
									}
									if (o.id === 4) {
										if (o.sendValue) {
											obj.dynamic_attributes = {
												choices: o.sendValue ? o.sendValue.map((k: any) => k.txt) : [],
											};
										} else {
											if (o.dynamic_attributes && o.dynamic_attributes.choices) {
												const sendValue = o.dynamic_attributes.choices.map((k: any) => {
													return {
														txt: k,
													};
												});
												o.sendValue = sendValue;
												obj.dynamic_attributes = {
													choices: sendValue ? sendValue.map((k: any) => k.txt) : [],
												};
											}
										}
									}
									if (o.id === 10) {
										obj.dynamic_attributes = {
											columns: o.tableStatistic.map((k: any) => k.name),
										};
									}
									if (o.id === 12) {
										obj.dynamic_attributes = {
											columns: o.tableCurrency.map((k: any) => {
												return {
													name: k.name,
													type: k.type,
												};
											}),
										};
									}
									return obj;
								});
								const allFollowers = [
									...this.defaultFollowers,
									...this.listAddedFollower.map((o: any) => (o && o.user ? o.user : null)),
								].filter(o => o && o.id);
								const body = {
									name: this.cateName,
									note: this.cateNote,
									description: this.cateDescription,
									department_id: this.department,
									default_approver_ids: this.listApprovalMember.map(o => o.id),
									default_followers: allFollowers.map((o: any) => (o ? o.id : '')),
									fields,
								};

								if (this.isEdit) {
									this.setLoadingSubmit(true);
									const res = await api.categories.update(this.currentID, body);
									this.setLoadingSubmit(false);
									if (!res) {
										return;
									}
									try {
										if (res.status > 199 && res.status < 399) {
											this.listAddedSigner = [];
											this.listUnsave = [];
											this.listUnsaveF = [];
											this.$store.commit('SET_SNACKBAR', {
												type: 'success',
												title: '',
												content: 'Cập nhật danh mục thành công',
											});
											this.setDefaultTitles(res.data.data.category.default_titles || []);
											this.setDefaultFollowers(res.data.data.category.default_followers || []);
											this.$store.dispatch('categories/getListCategories');
											this.$nextTick(() => {
												this.getDetail();
											});
										} else {
											this.setErrMsg(res.data.message || res.data.data.error);
											this.$store.commit('SET_SNACKBAR', {
												type: 'error',
												title: '',
												content: res.data.message || res.data.data.error,
											});
										}
									} catch (error) {
										console.log(error);
										this.$store.commit('SET_SNACKBAR', {
											type: 'error',
											title: '',
											content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
										});
									}
								} else {
									this.setLoadingSubmit(true);
									const res = await api.categories.create(body);
									this.setLoadingSubmit(false);
									if (!res) {
										return;
									}
									try {
										if (res.status > 199 && res.status < 399) {
											this.$router.push(`/danh-muc/chinh-sua/${res.data.data.category.id}`);
											this.$store.commit('SET_SNACKBAR', {
												type: 'success',
												title: '',
												content: 'Tạo mới danh mục thành công.',
											});
											this.$store.dispatch('categories/getListCategories');
										} else {
											this.setErrMsg(res.data.message || res.data.data.error);
											this.$store.commit('SET_SNACKBAR', {
												type: 'error',
												title: '',
												content: res.data.message || res.data.data.error,
											});
										}
									} catch (error) {
										console.log(error);
										this.$store.commit('SET_SNACKBAR', {
											type: 'error',
											title: '',
											content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
										});
									}
								}
							} else {
								this.setErrMsg(String(isValid));
							}
						} else {
							clearInterval(refIt);
						}
					} else {
						clearInterval(refIt);
						this.listAddedSigner = [];
						this.listUnsave.forEach(async o => {
							this.onUpdateDetailRow(o, false, false, false);
						});
						this.listUnsaveF.forEach(async o => {
							this.onUpdateDetailRow(o, false, false, false);
						});

						this.setErrMsg('');
						const isValid = await this.validSubmit();
						if (!isValid) {
							const fields = this.listFeildSelected.map(o => {
								let obj = {
									type_id: o.id,
									caption: o.caption,
									required: !!o.required,
									dynamic_attributes: {},
								};
								if (o.id === 3) {
									if (o.sendValue) {
										obj.dynamic_attributes = {
											choices: o.sendValue ? o.sendValue.map((k: any) => k.txt) : [],
										};
									} else {
										if (o.dynamic_attributes && o.dynamic_attributes.choices) {
											const sendValue = o.dynamic_attributes.choices.map((k: any) => {
												return {
													txt: k,
												};
											});
											o.sendValue = sendValue;
											obj.dynamic_attributes = {
												choices: sendValue ? sendValue.map((k: any) => k.txt) : [],
											};
										}
									}
								}
								if (o.id === 4) {
									if (o.sendValue) {
										obj.dynamic_attributes = {
											choices: o.sendValue ? o.sendValue.map((k: any) => k.txt) : [],
										};
									} else {
										if (o.dynamic_attributes && o.dynamic_attributes.choices) {
											const sendValue = o.dynamic_attributes.choices.map((k: any) => {
												return {
													txt: k,
												};
											});
											o.sendValue = sendValue;
											obj.dynamic_attributes = {
												choices: sendValue ? sendValue.map((k: any) => k.txt) : [],
											};
										}
									}
								}
								if (o.id === 10) {
									obj.dynamic_attributes = {
										columns: o.tableStatistic.map((k: any) => k.name),
									};
								}
								if (o.id === 12) {
									obj.dynamic_attributes = {
										columns: o.tableCurrency.map((k: any) => {
											return {
												name: k.name,
												type: k.type,
											};
										}),
									};
								}
								return obj;
							});
							const body = {
								name: this.cateName,
								note: this.cateNote,
								description: this.cateDescription,
								department_id: this.department,
								default_approver_ids: this.listApprovalMember.map(o => o.id),
								fields,
							};
							if (this.isEdit) {
								this.setLoadingSubmit(true);
								const res = await api.categories.update(this.currentID, body);
								this.setLoadingSubmit(false);
								if (!res) {
									return;
								}
								try {
									if (res.status > 199 && res.status < 399) {
										this.listAddedSigner = [];
										this.listUnsave = [];
										this.listUnsaveF = [];
										this.$store.commit('SET_SNACKBAR', {
											type: 'success',
											title: '',
											content: 'Cập nhật danh mục thành công',
										});
										this.setDefaultTitles(res.data.data.category.default_titles || []);
										this.setDefaultFollowers(res.data.data.category.default_followers || []);
										this.$store.dispatch('categories/getListCategories');
										// this.getDetail();
									} else {
										this.setErrMsg(res.data.message || res.data.data.error);
										this.$store.commit('SET_SNACKBAR', {
											type: 'error',
											title: '',
											content: res.data.message || res.data.data.error,
										});
									}
								} catch (error) {
									console.log(error);
									this.$store.commit('SET_SNACKBAR', {
										type: 'error',
										title: '',
										content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
									});
								}
							} else {
								this.setLoadingSubmit(true);
								const res = await api.categories.create(body);
								this.setLoadingSubmit(false);
								if (!res) {
									return;
								}
								try {
									if (res.status > 199 && res.status < 399) {
										this.$router.push(`/danh-muc/chinh-sua/${res.data.data.category.id}`);
										this.$store.commit('SET_SNACKBAR', {
											type: 'success',
											title: '',
											content: 'Tạo mới danh mục thành công.',
										});
										this.$store.dispatch('categories/getListCategories');
									} else {
										this.setErrMsg(res.data.message || res.data.data.error);
										this.$store.commit('SET_SNACKBAR', {
											type: 'error',
											title: '',
											content: res.data.message || res.data.data.error,
										});
									}
								} catch (error) {
									console.log(error);
									this.$store.commit('SET_SNACKBAR', {
										type: 'error',
										title: '',
										content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
									});
								}
							}
						} else {
							this.setErrMsg(String(isValid));
						}
					}
				}, 200);
			},
			async onRemoveDetailRow(_val: any) {
				if (_val.id) {
					this.approverLoading = true;
					const res = await api.categories.deleteDetailRow(this.currentID, _val.id);
					this.approverLoading = false;
					if (!res) {
						return;
					}
					try {
						if (res.status > 199 && res.status < 399) {
							this.$store.commit('SET_SNACKBAR', {
								type: 'success',
								title: '',
								content: 'Xóa người phê duyệt thành công',
							});
							this.getDetail();
							this.closeEditor();
						} else {
							this.setErrMsg(res.data.message || res.data.data.error);
							this.$store.commit('SET_SNACKBAR', {
								type: 'error',
								title: '',
								content: res.data.message || res.data.data.error,
							});
						}
					} catch (error) {
						console.log(error);
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
						});
					}
				}
			},
			async onRemoveDetailRowF(_val: any) {
				if (_val.id) {
					this.defaultFollowers = this.defaultFollowers.filter(o => o.id !== _val.id);
					this.onSubmit();
				}
			},

			async createDetailRow(_val: any, idx: number, loadingTable = true, forceReload = true) {
				return new Promise((resolve, reject) => {
					const payload: any = {
						title: _val.title,
					};
					if (_val.user) {
						payload.user_id = _val.user.id;
					}
					if (!String(payload.title).replaceAll(' ', '')) {
						return false;
					}
					if (loadingTable) {
						this.approverLoading = true;
					}
					(async () => {
						const res = await api.categories.createDetailRow(this.currentID, payload);
						this.approverLoading = false;
						if (!res) {
							reject(false);
							return;
						}
						try {
							if (res.status > 199 && res.status < 399) {
								this.$store.commit('SET_SNACKBAR', {
									type: 'success',
									title: '',
									content: 'Cập nhật phê duyệt thành công',
								});
								if (forceReload) {
									this.getDetail();
									this.selectedDetail = {};
									this.handleRemoveRow(idx);
								}
								resolve(true);
								return true;
							} else {
								this.setErrMsg(res.data.message || res.data.data.error);
								this.$store.commit('SET_SNACKBAR', {
									type: 'error',
									title: '',
									content: res.data.message || res.data.data.error,
								});
								reject(false);
								return false;
							}
						} catch (error) {
							console.log(error);
							this.$store.commit('SET_SNACKBAR', {
								type: 'error',
								title: '',
								content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
							});
							reject(false);
							return false;
						}
					})();
				});
			},
			async createDetailRowF(_val: any, idx: number, loadingTable = true, forceReload = true) {
				return new Promise((resolve, reject) => {
					const payload: any = {
						title: _val.title,
					};
					if (_val.user) {
						payload.user_id = _val.user.id;
					}
					if (!String(payload.title).replaceAll(' ', '')) {
						return false;
					}
					if (loadingTable) {
						this.approverLoading = true;
					}
					(async () => {
						const res = await api.categories.createDetailRow(this.currentID, payload);
						this.approverLoading = false;
						if (!res) {
							reject(false);
							return;
						}
						try {
							if (res.status > 199 && res.status < 399) {
								this.$store.commit('SET_SNACKBAR', {
									type: 'success',
									title: '',
									content: 'Cập nhật phê duyệt thành công',
								});
								if (forceReload) {
									this.getDetail();
									this.selectedDetail = {};
									this.handleRemoveRow(idx);
								}
								resolve(true);
								return true;
							} else {
								this.setErrMsg(res.data.message || res.data.data.error);
								this.$store.commit('SET_SNACKBAR', {
									type: 'error',
									title: '',
									content: res.data.message || res.data.data.error,
								});
								reject(false);
								return false;
							}
						} catch (error) {
							console.log(error);
							this.$store.commit('SET_SNACKBAR', {
								type: 'error',
								title: '',
								content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
							});
							reject(false);
							return false;
						}
					})();
				});
			},
			async onUpdateDetailRow(_val: any, emptyUser = false, loadingTable = true, forceReload = true) {
				if (_val.id) {
					const payload = {
						title: _val.title,
						user_id: _val.user ? _val.user.id : '',
					};
					if (!String(payload.title).replaceAll(' ', '')) {
						return false;
					}
					if (emptyUser || !payload.user_id) {
						delete payload.user_id;
					}
					if (loadingTable) {
						this.approverLoading = true;
					}
					const res = await api.categories.updateDetailRow(this.currentID, _val.id, payload);
					this.approverLoading = false;
					if (!res) {
						return false;
					}
					try {
						if (res.status > 199 && res.status < 399) {
							this.$store.commit('SET_SNACKBAR', {
								type: 'success',
								title: '',
								content: 'Cập nhật danh mục thành công',
							});
							if (forceReload) {
								this.getDetail();
								this.selectedDetail = {};
							}
							this.removeChange(_val);
							return true;
						} else {
							this.setErrMsg(res.data.message || res.data.data.error);
							this.$store.commit('SET_SNACKBAR', {
								type: 'error',
								title: '',
								content: res.data.message || res.data.data.error,
							});
							return false;
						}
					} catch (error) {
						console.log(error);
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
						});
						return false;
					}
				}
			},
			async onUpdateDetailRowF(_val: any, emptyUser = false, loadingTable = true, forceReload = true) {
				// update dùng cho default followers

				if (_val.id) {
					const payload = {
						title: _val.title,
						user_id: _val.user ? _val.user.id : '',
					};
					if (!String(payload.title).replaceAll(' ', '')) {
						return false;
					}
					if (emptyUser || !payload.user_id) {
						delete payload.user_id;
					}
					if (loadingTable) {
						this.approverLoading = true;
					}
					const res = await api.categories.updateDetailRowF(this.currentID, _val.id, payload);
					this.approverLoading = false;
					if (!res) {
						return false;
					}
					try {
						if (res.status > 199 && res.status < 399) {
							this.$store.commit('SET_SNACKBAR', {
								type: 'success',
								title: '',
								content: 'Cập nhật danh mục thành công',
							});
							if (forceReload) {
								this.getDetail();
								this.selectedDetail = {};
							}
							this.removeChange(_val);
							return true;
						} else {
							this.setErrMsg(res.data.message || res.data.data.error);
							this.$store.commit('SET_SNACKBAR', {
								type: 'error',
								title: '',
								content: res.data.message || res.data.data.error,
							});
							return false;
						}
					} catch (error) {
						console.log(error);
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
						});
						return false;
					}
				}
			},
			async submitEditor(_val: any) {
				const body = _val;
				console.log(_val);
				if (_val.id) {
					this.setLoadingSubmit(true);
					const res = await api.template.update(this.currentID, _val.id, body);
					this.setLoadingSubmit(false);
					if (!res) {
						return;
					}
					try {
						if (res.status > 199 && res.status < 399) {
							this.$store.commit('SET_SNACKBAR', {
								type: 'success',
								title: '',
								content: 'Cập nhật danh mục thành công',
							});
							this.$nextTick(() => {
								this.getDetail();
							});
							this.closeEditor();
						} else {
							this.setErrMsg(res.data.message || res.data.data.error);
							this.$store.commit('SET_SNACKBAR', {
								type: 'error',
								title: '',
								content: res.data.message || res.data.data.error,
							});
						}
					} catch (error) {
						console.log(error);
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
						});
					}
				} else {
					this.setLoadingSubmit(true);
					const res = await api.template.create(this.currentID, body);
					this.setLoadingSubmit(false);
					if (!res) {
						return;
					}
					try {
						if (res.status > 199 && res.status < 399) {
							this.$store.commit('SET_SNACKBAR', {
								type: 'success',
								title: '',
								content: 'Cập nhật danh mục thành công',
							});
							this.getDetail();
							this.closeEditor();
						} else {
							this.setErrMsg(res.data.message || res.data.data.error);
							this.$store.commit('SET_SNACKBAR', {
								type: 'error',
								title: '',
								content: res.data.message || res.data.data.error,
							});
						}
					} catch (error) {
						console.log(error);
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
						});
					}
				}
			},
			onChangeUser(data: Record<string, any>) {
				this.setVisibleAddMember(true);
				this.selectedDetail = { ...data };
				this.setListApprovalMember(data.user ? [data.user] : []);
			},
			onChangeUserF(data: Record<string, any>) {
				this.setVisibleAddMemberF(true);
				this.selectedDetail = { ...data };
				this.setListFollowersMember(data ? [data] : []);
			},
			onChangeNewUser(data: Record<string, any>, idx: number) {
				this.setVisibleAddMember(true);
				this.selectedDetail = { ...data, newIdx: idx };
				this.setListApprovalMember(data.user ? [data.user] : []);
			},
			onChangeNewUserF(data: Record<string, any>, idx: number) {
				this.setVisibleAddMemberF(true);
				this.selectedDetail = { ...data, newIdx: idx };
				this.setListFollowersMember(data.user ? [data.user] : []);
			},
			handleAddRow() {
				this.listAddedSigner.push({
					title: '',
				});
			},
			handleAddRowF() {
				this.listAddedFollower.push({});
			},
			handleRemoveRow(idx: number) {
				this.listAddedSigner.splice(idx, 1);
			},
			handleRemoveRowF(idx: number) {
				this.listAddedFollower.splice(idx, 1);
			},
			selectToChangeTitle(each: Record<string, any>, idx: number | string) {
				this.selectedDefaultTitles = {
					idx,
					each: { ...each },
				};
				this.dialogInputTitle = true;
			},
			selectToChangeTitleF(each: Record<string, any>, idx: number | string) {
				this.selectedDefaultTitlesF = {
					idx,
					each: { ...each },
				};
				this.dialogInputTitleF = true;
			},
			selectToChangeNewTitle(each: Record<string, any>, idx: number | string) {
				this.selectedNewTitles = {
					idx,
					each: { ...each },
				};
				this.dialogInputTitle = true;
			},
			selectToChangeNewTitleF(each: Record<string, any>, idx: number | string) {
				this.selectedNewTitlesF = {
					idx,
					each: { ...each },
				};
				this.dialogInputTitleF = true;
			},
			cancelToChangeTitle() {
				this.selectedDefaultTitles = {};
				this.dialogInputTitle = false;
			},
			cancelToChangeTitleF() {
				this.selectedDefaultTitlesF = {};
				this.dialogInputTitleF = false;
			},
			onChangeDefaultTitle() {
				if (this.selectedDefaultTitles.each) {
					const arr = this.defaultTitles.slice();
					arr[this.selectedDefaultTitles.idx] = this.selectedDefaultTitles.each;
					this.setDefaultTitles(arr);
					// this.listUnsave.push(this.selectedDefaultTitles.each);
					this.onUpdateDetailRow(this.selectedDefaultTitles.each);
					this.$nextTick(() => {
						this.selectedDefaultTitles = {};
						this.selectedNewTitles = {};
						this.dialogInputTitle = false;
					});
				}
				if (this.selectedNewTitles.each) {
					const arr = this.listAddedSigner.slice();
					arr[this.selectedNewTitles.idx] = this.selectedNewTitles.each;
					this.listAddedSigner = [...arr];
					this.$nextTick(() => {
						this.selectedNewTitles = {};
						this.selectedDefaultTitles = {};
						this.dialogInputTitle = false;
					});
				}
			},

			onEnterTitle(_val: string) {
				if (_val.trim()) {
					this.onChangeDefaultTitle();
				}
			},
		},
	});
