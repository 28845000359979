














































































import {defineComponent,ref,watch} from "@vue/composition-api"
import TextFilter from "@/components/Shared/TextFilter.vue"
import TableFiltersSelect from "@/components/Shared/TableFiltersSelect.vue"

export default defineComponent({
  components:{
    TextFilter,
    TableFiltersSelect
  },
  props: {
    isVisibleApproval: {
      type: Boolean,
      default: false,
    },
    handleDialogCancel:{
        type:Function
    },
    header:{
        type:[]
    },
    settings:{
        type:[]
    }
    
  },
  setup: (props, ctx) => {

    const btnCancelClick= ()=>{
        ctx.emit("handleDialogCancel")
    }
    return {
        btnCancelClick
    };
  },


})
