





































































import {defineComponent, ref} from '@vue/composition-api';
import {tStr} from 'validation_t/src';
import api from '@/services';
import {useState} from 'vuex-composition-helpers/dist';

import {mapState} from 'vuex';
// import "@/plugins/textindent/plugin";
export default defineComponent({
  components: {
    'ckeditor-nuxt': () => {
      return import('@blowstack/ckeditor-nuxt');
    },
  },
  props: {
    currentCateId: {
      type: Number,
      default: 1,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    selectedTemplate: {
      type: Object,
      default() {
        return {};
      },
    },
    onClose: {
      type: Function,
    },
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
    handlePreview: {
      type: Function,
      default() {
        return {};
      },
    },
    listParam: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup: (props: any, ctx: any) => {
    const {auth} = useState(['auth']);
    let content = '';
    const token = auth.value.token;
    const isA4 = ref<boolean>(true);
    const loadingBtnPreview = ref<boolean>(false);
    const name = ref<string>('');
    const title = ref<string>('');
    const version = ref<string>('');
    const code = ref<string>('');
    const currentCap = ref<string>('');
    const errMsg = ref<string>('');
    const selected = ref<any[]>([]);
    const editorConfig = {
      toolbar: {
        //  items: ["bold", "italic", "|", "undo", "redo", "-", "numberedList", "bulletedList"],

        shouldNotGroupWhenFull: true,
      },
      exportPdf: {},
      // pagination: {
      //  // A4
      //  pageWidth: "21cm",
      //  pageHeight: "29.7cm",

      //  pageMargins: {
      //   top: "20mm",
      //   bottom: "20mm",
      //   left: "12mm",
      //   right: "12mm",
      //  },
      // },
      removePlugins: [
        'Title',
        'CodeBlock',
        'Code',
        'MathType',
        'MediaEmbed',
        'PageBreak',
        'Subscript',
        'Superscript',
        'BlockQuote',
        'Strikethrough',
        'HorizontalLine',
        'Table',
        'FontBackgroundColor',
        'TodoList',
      ],
      simpleUpload: {
        uploadUrl: process.env.BASE_URL + '/api/user/announcements/upload-image',
        headers: {
          Authorization: token,
        },
      },
    };
    const setIsA4 = (val: boolean) => {
      isA4.value = val;
    };
    const setName = (val: string) => {
      name.value = val;
    };
    const setTitle = (val: string) => {
      title.value = val;
    };
    const setCode = (val: string) => {
      code.value = val;
    };
    const setVersion = (val: string) => {
      version.value = val;
    };

    return {
      auth,
      currentCap,
      name,
      title,
      code,
      version,
      content,
      selected,
      editorConfig,
      loadingBtnPreview,
      isA4,
      setIsA4,
      setName,
      setTitle,
      setCode,
      setVersion,
      errMsg,
    };
  },
  data() {
    return {
      editorData: '',

      show_proposer: false,
      editorConfig: {
        removePlugins: 'iframe,image',
        toolbarGroups: [
          {name: 'styles'},
          {name: 'colors'},
          {name: 'basicstyles'},
          // { name: "document", groups: ["document", "doctools"] },

          // { name: "clipboard", groups: ["clipboard", "undo"] },
          {name: 'forms'},

          {
            name: 'paragraph',
            groups: ['list', 'blocks', 'align', 'TextIndent', 'indent'],
          },
          {name: 'tools', groups: ['table']},
          {name: 'insert', groups: ['PageBreak']},
        ],
        extraPlugins: ['pagebreak', 'justify', 'textindent', 'indentblock', 'timestamp'],
        indentation: '20px',
        indentationKey: 'tab',
      },
    };
  },
  watch: {
    isVisible() {
      this.errMsg = '';
      if (this.isVisible && this.currentCK4) {
        console.log('sz');
        this.onNamespaceLoaded(this.currentCK4);
      }
    },
    selected: {
      deep: true,
      handler() {
        const str = `<p class="${this.returnName(this.selected[0])}">${this.selected[0]}:{!${this.returnName(
            this.selected[0]
        )}!}</p>`;
        this.content += str;
      },
    },
    selectedTemplate: {
      deep: true,
      handler() {
        if (this.selectedTemplate.id) {
          this.setName(this.selectedTemplate.name);
          this.setTitle(this.selectedTemplate.title);
          this.setCode(this.selectedTemplate.code);
          this.setVersion(this.selectedTemplate.version);

          this.editorData = this.selectedTemplate.html;
          this.show_proposer = this.selectedTemplate.show_proposer;
          // this.onNamespaceLoaded(Vue.CKEDITOR);
        }
      },
    },
  },
  mounted() {
    localStorage.setItem('listParamComputed', JSON.stringify(this.listParamComputed));

    if (this.isVisible && this.currentCK4) {
      // this.currentCK4.plugins.load('timestamp', function (plugins: any) {
      // 	alert(plugins['myplugin']); // object
      // });
      // this.currentCK4.replace('myck4');
      // this.onNamespaceLoaded(this.currentCK4);
      // this.currentCK4.replace('timestamp', {
      // 	icons: 'timestamp',
      // 	init: function (editor: any) {
      // 		editor.addCommand('insertTimestamp', {
      // 			exec: function (editor: any) {
      // 				var now = new Date();
      // 				editor.insertHtml(
      // 					'The current date and time is: <em>' +
      // 						now.toString() +
      // 						'</em>'
      // 				);
      // 			},
      // 		});
      // 		// editor.ui.addButton("Timestamp", {
      // 		//  label: "Insert Timestamp",
      // 		//  command: "insertTimestamp",
      // 		//  toolbar: "insert",
      // 		// });
      // 		let config = editor.config;
      // 		editor.ui.addRichCombo('my-combo', {
      // 			label: 'Danh sách trường',
      // 			title: 'Danh sách trường',
      // 			toolbar: 'insert',
      // 			panel: {
      // 				css: [this.currentCK4.skin.getPath('editor')].concat(
      // 					config.contentsCss
      // 				),
      // 				multiSelect: false,
      // 				attributes: { 'aria-label': 'Danh sách trường' },
      // 			},
      // 			init: function () {
      // 				this.startGroup('Danh sách các trường');
      // 				this.listParamComputed.forEach((o: any, idx: number) => {
      // 					this.add(`{!${o.name}!}`, `${o.caption}`);
      // 				});
      // 			},
      // 			onClick: function (value: any) {
      // 				editor.focus();
      // 				editor.fire('saveSnapshot');
      // 				editor.insertHtml(value);
      // 				editor.fire('saveSnapshot');
      // 			},
      // 		});
      // 	},
      // });
    }
  },
  computed: {
    ...mapState({
      currentCK4: (state: any) => state.currentCK4,
    }),
    listParamComputed(): any {
      if (!this.listParam) {
        return [];
      }
      const listCaption = Object.values(this.listParam);
      const listName = Object.keys(this.listParam);
      const arr = listCaption.map((o: any, idx: number) => {
        return {
          caption: o,
          name: listName[idx],
        };
      });
      return arr;
    },
  },
  // mounted() {},
  methods: {
    test() {
      console.log(this.$refs.myNxtEditor);
      // console.log(this.$refs.myNxtEditor.editor);
    },
    async onPreview() {
      const body = {
        html: this.editorData,
        code: this.code,
        version: this.version,
        title: this.title,
      };
      this.loadingBtnPreview = true;
      const res = await api.template.preview(this.currentCateId, body);
      this.loadingBtnPreview = false;
      if (!res) {
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
        return;
      }
      try {
        if (res.status > 199 && res.status < 400) {
          const pv = res.data.data;
          this.handlePreview(pv);
        } else {
          this.$store.commit('SET_SNACKBAR', {
            type: 'error',
            title: '',
            content: res.data.message || res.data.data.error,
          });
        }
      } catch (error) {
        console.log(error);
        this.$store.commit('SET_SNACKBAR', {
          type: 'error',
          title: '',
          content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
        });
      }
      console.log(res);
    },
    onEditorInput(e: any) {
      console.log(e);
    },
    onEditorFocus(e: any) {
      console.log(e);
    },
    onEditorReady() {
      const target = document.querySelector('#edtor-wrapper_m');
      if (target) {
        const toolbar = target.querySelector('.ck.ck-toolbar__items');
        console.log(toolbar);
      }
      alert('rd');
    },
    handleInsertCap() {
      if (!this.currentCap) {
        return;
      }
      const objResultCap = this.listParamComputed.filter((o: any) => o.name === this.currentCap);
      if (!objResultCap || !objResultCap.length) {
        return;
      }
      const str = `{!${objResultCap[0].name}!}`;
      this.content += str;
    },
    capitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    returnName(_str: string) {
      if (!_str) {
        return _str;
      }
      const result = tStr.removeAscentNormal(_str);
      const arrResult = result.split(' ');
      arrResult.forEach((o: string) => {
        o = this.capitalizeFirstLetter(o);
      });
      return String(arrResult.join(''))
          .replace(/[^\w\s]/gi, '')
          .replaceAll(' ', '_');
    },
    handleChangeField(e: Event, _val: any) {
      console.log(_val);
      if (e) {
        const str = `<p class="${this.returnName(_val.caption)}">${_val.caption}:{!${_val.name}!}</p>`;
        this.content += str;
      } else {
        const str = `<p>${_val.caption}:{!${_val.name}!}</p>`;

        this.content = this.content.replaceAll(str, '');
      }
    },
    handleSubmit() {
      this.errMsg = '';
      if (!this.name) {
        this.errMsg = 'Không được để trống tên tài liệu mẫu';
        return;
      }
      const obj = {
        name: this.name,
        code: this.code,
        title: this.title,
        version: this.version,
        html: this.editorData,
        id: 0,
        show_proposer: this.show_proposer,
      };
      if (this.selectedTemplate.id) {
        obj.id = this.selectedTemplate.id;
      }
      this.onSubmit(obj);
    },
    handleClose() {
      this.onClose();
    },
    onNamespaceLoaded(CKEDITOR: any) {
      // Add external `placeholder` plugin which will be available for each
      // editor instance on the page.
      this.$store.commit('SET_CURRENT_CK4', CKEDITOR);
      // const listParamComputed = this.listParamComputed;
      CKEDITOR.plugins.add('textindent', {
        icons: 'textindent',
        // availableLangs: { vi: 1 },
        // lang: "vi",
        init: function (editor: any) {
          var indentation = editor.config.indentation;
          var indentationKey = editor.config.indentationKey;

          if (typeof indentation == 'undefined') indentation = '20px';
          if (typeof indentationKey == 'undefined') indentationKey = 'tab';

          // if (editor.ui.addButton) {
          //   editor.ui.addButton("textindent", {
          //     label: "Ds",
          //     title: "Ds",
          //     icons: "textindent",
          //     command: "ident-paragraph",
          //     toolbar: "paragraph",
          //   });
          // }

          if (indentationKey !== false) {
            editor.on('key', function (ev: any) {
              if (
                  ev.data.domEvent.$.key.toLowerCase() === indentationKey.toLowerCase().trim() ||
                  ev.data.keyCode === indentationKey
              ) {
                editor.execCommand('ident-paragraph');
                ev.cancel();
              }
            });
          }

          editor.on('selectionChange', function () {
            var style_textindente = new CKEDITOR.style({
              element: 'p',
              styles: {'text-indent': indentation},
              overrides: [
                {
                  element: 'text-indent',
                  attributes: {size: '0'},
                },
              ],
            });

            if (style_textindente.checkActive(editor.elementPath(), editor))
              editor.getCommand('ident-paragraph').setState(CKEDITOR.TRISTATE_ON);
            else editor.getCommand('ident-paragraph').setState(CKEDITOR.TRISTATE_OFF);
          });

          editor.addCommand('ident-paragraph', {
            allowedContent: 'p{text-indent}',
            requiredContent: 'p',
            exec: function (evt: any) {
              var range = editor.getSelection().getRanges()[0];

              var walker = new CKEDITOR.dom.walker(range),
                  node;

              var state = editor.getCommand('ident-paragraph').state;

              while ((node = walker.next())) {
                if (node.type == CKEDITOR.NODE_ELEMENT) {
                  if (node.getName() === 'p') {
                    editor.fire('saveSnapshot');
                    if (state == CKEDITOR.TRISTATE_ON) {
                      node.removeStyle('text-indent');
                      editor.getCommand('ident-paragraph').setState(CKEDITOR.TRISTATE_OFF);
                    } else {
                      node.setStyle('text-indent', indentation);
                      editor.getCommand('ident-paragraph').setState(CKEDITOR.TRISTATE_ON);
                    }
                  }
                }
              }

              if (node === null) {
                node = editor.getSelection().getStartElement().getAscendant('p', true);

                editor.fire('saveSnapshot');

                if (state == CKEDITOR.TRISTATE_ON) {
                  node.removeStyle('text-indent');
                  editor.getCommand('ident-paragraph').setState(CKEDITOR.TRISTATE_OFF);
                } else {
                  node.setStyle('text-indent', indentation);
                  editor.getCommand('ident-paragraph').setState(CKEDITOR.TRISTATE_ON);
                }
              }
            },
          });
        },
      });

      CKEDITOR.plugins.add('timestamp', {
        icons: 'timestamp',
        init: function (editor: any) {
          editor.addCommand('insertTimestamp', {
            exec: function (editor: any) {
              var now = new Date();
              editor.insertHtml('The current date and time is: <em>' + now.toString() + '</em>');
            },
          });
          // editor.ui.addButton("Timestamp", {
          //  label: "Insert Timestamp",
          //  command: "insertTimestamp",
          //  toolbar: "insert",
          // });
          let config = editor.config;
          editor.ui.addRichCombo('my-combo', {
            label: 'Danh sách trường',
            title: 'Danh sách trường',
            className: 'list-params',
            toolbar: 'insert',
            panel: {
              css: [CKEDITOR.skin.getPath('editor')].concat(config.contentsCss),
              multiSelect: false,
              attributes: {'aria-label': 'Danh sách trường'},
            },

            init: function () {
              const localListParamComputed = localStorage.getItem('listParamComputed');
              if (localListParamComputed) {
                const newList = JSON.parse(localListParamComputed);
                this.startGroup('Danh sách các trường');
                newList.forEach((o: any, idx: number) => {
                  this.add(`{!${o.name}!}`, `${o.caption}`);
                });
              }
            },

            onClick: function (value: any) {
              editor.focus();
              editor.fire('saveSnapshot');

              editor.insertHtml(value);

              editor.fire('saveSnapshot');
            },
          });
        },
      });
    },
  },
});
