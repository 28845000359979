


























import {
 reactive,
 ref,
 defineComponent,
 toRef,
 toRefs,
} from "@vue/composition-api";
import { mapState } from "vuex";
import api from "@/services";
export default defineComponent({
 props: {
  isVisible: {
   type: Boolean,
   default: false,
  },
  handlerCancel: {
   type: Function,
  },

  selectedData: {
   type: Object,
  },
  //  innerContent: {
  //   type: String,
  //   default: "",
  //  },
 },
 data() {
  return {
   innerContent: "",
  };
 },
 watch: {
  selectedData: {
   deep: true,
   handler() {
    if (this.selectedData && this.selectedData.html) {
     this.innerContent = this.selectedData.html;
    }
   },
  },
 },
 methods: {
  //  async getTemplatePreview() {
  //   console.log(this.selectedData);
  //   if (this.selectedData) {
  //    const id = this.selectedData.id;
  //    const res = await api.template.preview(id);
  //    if (!res) {
  //     this.$store.commit("SET_SNACKBAR", {
  //      type: "error",
  //      title: "",
  //      content: "Có lỗi hệ thống khi lấy dữ liệu. Liên hệ IT để được trợ giúp.",
  //     });
  //     return;
  //    }
  //    try {
  //     if (res.status > 199 && res.status < 399) {
  //      this.innerContent = res.data.data.html;
  //     } else {
  //      this.$store.commit("SET_SNACKBAR", {
  //       type: "error",
  //       title: "",
  //       content:
  //        res.data.data.error ||
  //        res.data.message ||
  //        "Có lỗi hệ thống khi lấy dữ liệu.",
  //      });
  //     }
  //    } catch (error) {
  //     console.log(error);
  //    }
  //   }
  //  },
 },
});
